
import AvatarImage from "@/components/AvatarImage.vue";
import { UsersModule } from "@/store/modules/users";
import { PopoutsModule } from "@/store/modules/popouts";
import { ServerRolesModule } from "@/store/modules/serverRoles";
import { ServerMembersModule } from "@/store/modules/serverMembers";
import { addRole, removeRole } from "@/services/userService";
import { ServersModule } from "@/store/modules/servers";
import { MeModule } from "@/store/modules/me";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { AvatarImage },
  props: {
    data: {
      type: Object as PropType<{ id: string; serverID: string }>,
      required: true,
    },
  },
  computed: {
    serverRoles(): any {
      const serverID = this.data.serverID;
      const id = this.data.id;
      return ServerRolesModule.sortedServerRolesArr(this.data.serverID)
        .filter((r) => !r.default && r.deletable)
        .map((role) => {
          const hasRole = ServerMembersModule.memberHasRole(
            serverID,
            id,
            role.id
          );
          const canModify =
            this.isServerCreator || this.myHighestRoleOrder < role.order;
          return {
            ...role,
            canModify,
            hasRole,
          };
        });
    },
    myHighestRoleOrder(): any {
      return (
        ServerMembersModule.highestRoleOrder(
          this.data.serverID,
          MeModule?.user?.id || ""
        ) || 0
      );
    },
    isServerCreator(): any {
      return ServersModule.isServerOwner(
        this.data.serverID,
        MeModule?.user?.id || ""
      );
    },
    user(): any {
      return UsersModule.users[this.data.id] || {};
    },
  },
  methods: {
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        PopoutsModule.ClosePopout("edit-role");
      }
    },
    roleClicked(role: { hasRole: string; id: string; canModify: boolean }) {
      if (!role.canModify) return;
      const func = role.hasRole ? removeRole : addRole;
      func(this.data.serverID, this.data.id, role.id);
    },
  },
});
